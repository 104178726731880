import { Route } from '@angular/router';
import { authenticated, unauthenticated } from 'auth';
import { hasJob} from './modules/pin/store/pin.guards';
import { hasPin } from './modules/question/store/question.guards';

export const appRoutes: Route[] = [
  {
    path: 'auth',
    canActivate: [unauthenticated('/')],
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'pin',
    canActivate: [authenticated('/auth'), hasJob()],
    loadChildren: () =>
      import('./modules/pin/pin.module').then((m) => m.PinModule),
  },
  {
    path: '',
    canActivate: [authenticated('/auth'), hasPin('/pin')],
    loadChildren: () =>
      import('./modules/question/question.module').then(
        (m) => m.QuestionModule,
      ),
  },
  { path: '**', redirectTo: '' },
];
