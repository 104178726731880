import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { LoadingComponent } from 'loading';
import { appRoutes } from './app.routes';
import { AUTH_ENDPOINT } from 'auth';
import { FormlyModule } from 'formly';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes),
    BrowserAnimationsModule,
    LoadingComponent,
    FormlyModule,
  ],
  providers: [{ provide: AUTH_ENDPOINT, useValue: 'auth/loginJob' }],
  bootstrap: [AppComponent],
})
export class AppModule {}
